import React from 'react';
import { graphql } from 'gatsby';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';
import Events from '../components/Events';
import YouTube from "../components/YouTube";

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        byline
        youtube
      }
    }
  }
`;

const StyledGalleryWrapper = styled.div`
  position: relative;

  .overlay {
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .byline {
    background-color: rgba(184, 38, 52, 0.85);
    color: white;
    padding: 1em;
    font-size: 1.2em;
    width: fit-content;
    max-width: 500px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 3em;
  grid-template-areas:
    "events music";

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-gap: 1em;
    grid-template-areas:
      "events"
      "music";
  }

  .events {
    grid-area: events;
  }

  .music {
    grid-area: music;
  }
`;

interface TemplateProps {
  image: string;
  byline: string;
  youtube: string;
  isEditMode?: boolean;
}

export const IndexPageTemplate = ({
  image,
  byline,
  youtube,
  isEditMode = false,
}: TemplateProps) => (
  <div>
    <StyledGalleryWrapper>
      <Gallery items={[{
        original: image,
        thumbnail: image,
      }]}
      />
      <Container maxWidth="md" className="overlay">
        <p className="byline">{byline}</p>
      </Container>
    </StyledGalleryWrapper>
    <Container maxWidth="md">
      <StyledContent>
        <div className="events">
          <h1>Upcoming Events</h1>
          {!isEditMode ? <Events /> : <p>Events are hidden in the preview.</p>}
        </div>
        <div className="music">
          <h1>Music</h1>
          <YouTube videoId={youtube} />
        </div>
      </StyledContent>
    </Container>
  </div>
);

interface PageProps {
  data: {
    markdownRemark: {
      frontmatter: any;
    };
  };
}

const IndexPage = ({ data }: PageProps) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image.childImageSharp.fluid.src}
        byline={frontmatter.byline}
        youtube={frontmatter.youtube}
      />
    </Layout>
  );
};

export default IndexPage;
