import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';

interface Props {
  items: ReactImageGalleryItem[];
}

function Gallery(props: Props) {
  const { items } = props;
  return (
    <ImageGallery items={items} showThumbnails={false} />
  );
}

export default Gallery;
