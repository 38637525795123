import React from 'react';
import styled from 'styled-components';
import ReactYouTube, { YouTubeProps } from 'react-youtube';

const StyledWrapper = styled.div`
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function YouTube(props: YouTubeProps) {
  const { videoId } = props;
  return (
    <StyledWrapper>
      <ReactYouTube
        videoId={videoId}
        opts={{
          height: '100%',
          width: '100%',
        }}
      />
    </StyledWrapper>
  );
}

export default YouTube;
